// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { useHoverIntent } from 'react-use-hoverintent'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const Section = React.memo(({ mediaState: { currentWidth } }) => {
  const [isHovering1, ref1] = useHoverIntent()
  const [isHovering2, ref2] = useHoverIntent()
  const [isHovering3, ref3] = useHoverIntent()
  const [isHovering4, ref4] = useHoverIntent()

  return (
    <section id="insights">
      <div>
        <div ref={ref1} className={`${isHovering1 ? 'activeX' : ''}`}>
          <h2>The timeline</h2>
        </div>
        <div ref={ref2} className={`${isHovering2 ? 'activeX' : ''}`}>
          <h2>The map</h2>
        </div>
        <div ref={ref3} className={`${isHovering3 ? 'activeX' : ''}`}>
          <h2>A data-visual of the categories</h2>
        </div>
        <div ref={ref4} className={`${isHovering4 ? 'activeX' : ''}`}>
          <h2>A keyword cloud</h2>
        </div>
      </div>
    </section>
  )
})

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    // headerState: state.headerState,
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSection = compose(
  withState // Add state
)(Section)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedSection
