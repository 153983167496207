// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import map from 'lodash/map'
import chunk from 'lodash/chunk'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import VistaY from '../vista/vista-y'
// import '../vista/style.less'

import ArkImg from '../ark-img'
import '../ark-img/style.less'

import mostRecentAdditions from '../../data/most-recent-additions.json'

// import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const chunks = chunk(mostRecentAdditions, 8)
// const { Fragment } = React

// const ids = [
//   smallKey(),
//   smallKey(),
//   smallKey(),
//   smallKey(),
//   smallKey(),
//   smallKey(),
//   smallKey(),
//   smallKey(),
// ]

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Section = React.memo(({ mediaState: { currentWidth } }) => (
  <section id="most-recent-additions-to-the-open-access-archive">
    <Row justify="space-between">
      <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
        {map(chunks, (c, index) => (
          <div>
            {map(c, ({ fileId, title, originalAspectRatio }) => (
              <ArkImg
                fileId={fileId}
                title={title}
                aspectRatio={originalAspectRatio}
                width={200}
              />
            ))}
          </div>
        ))}
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
        <h2>Open-Access Archive</h2>
        <p>
          The Auroville Archives Open-Access Archive is a free service and an
          open-access archive for ... media objects in the fields of ...(the
          categories). Materials in our Open-Access are reviewed and curated by
          the Auroville Archives team.
        </p>
      </Col>
    </Row>
  </section>
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    // headerState: state.headerState,
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSection = compose(
  withState // Add state
)(Section)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedSection
