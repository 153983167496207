// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { StaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Slider from '../slider'
import '../slider/style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'
// import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

export const query = graphql`
  query {
    featuredTrueStories: allFile(
      limit: 6
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/true-stories/))/" }
          autoNodeType: { eq: "resourceNode" }
        }
      }
      sort: { fields: [fields___publishedUnixTimestampAuto], order: DESC }
    ) {
      nodes {
        fields {
          pathAuto
          publishedShortHumandateAuto
        }
        childResources {
          title
          coverImage: coverImagePath {
            childImageSharp {
              gatsbyImageData(
                width: 900
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const ResourceRenderer = React.memo(({ node: { title, coverImage } }) => (
  <div>
    <GatsbyImage image={getImage(coverImage)} />
    <div className="about">
      <p>
        <strong>Featured Story</strong>
      </p>
      <h2>{title}</h2>
      <p>an abstract...</p>
    </div>
  </div>
))

/** [description] */
const Section = React.memo(({ mediaState: { currentWidth } }) => (
  <StaticQuery
    query={query}
    render={({
      featuredTrueStories: { nodes },
      flat = map(nodes, flattenGatsbyNode),
    }) => (
      <section id="featured-true-stories">
        <h1>Stories</h1>
        <p>
          blah blah blah blah blah blah blah blah blah blah blah blah blah blah
          blah blah blah blah blah blah blah blah blah blah blah blah blah blah
          blah blah blah blah blah blah blah blah blah blah blah blah blah blah
          blah blah blah blah blah blah.
        </p>
        <Slider
          className="ext-arrow variable-width"
          conf={{ slidesToShow: 3, slidesToScroll: 1 }}
          nodes={flat}
          ResourceRenderer={ResourceRenderer}
        />
      </section>
    )}
  />
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    // headerState: state.headerState,
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSection = compose(
  withState // Add state
)(Section)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedSection
