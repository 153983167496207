// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const presets = {
  default: ['#EE7752', '#E73C7E', '#23A6D5', '#23D5AB'],
  cyberPunk: ['#00ff9f', '#00b8ff', '#001eff', '#bd00ff', '#d600ff'],
  life: ['#F9ED69', '#F08A5D', '#B83B5E', '#6A2C70'],
  blue: ['#48466D', '#3D84A8', '#46CDCF', '#ABEDD8'],
  gray: ['#FAFAFA', '#F2F2F2', '#EAEAEA', '#969696'],
  grey: ['#FAFAFA', '#F2F2F2', '#EAEAEA', '#969696'],
  earth: ['#EDDE91', '#D99751', '#E1B07B', '#8D5B32', '#6A4128'],
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Img */
const Img = ({
  fileId,
  aspectRatio,
  width,
  title,
  widths = [100, 200],
  fallback = 200,
  store = 'n1',
  preset = 'gray',
}) => (
  <div
    className="gatsby-image-wrapper ark-img"
    suppresshydrationwarning="true"
    style={{ width, height: width / aspectRatio }}
    title={title}
  >
    <div
      className="animated-placeholder"
      style={{
        background: `linear-gradient(-45deg, ${presets[preset].join(', ')})`,
        backgroundSize: '600% 600%',
        WebkitAnimationDuration: `${
          Math.floor(Math.random() * 500 + 1) * 4 + 1500
        }ms`,
        MozAnimationDuration: `${
          Math.floor(Math.random() * 500 + 1) * 4 + 1500
        }ms`,
        AnimationDuration: `${
          Math.floor(Math.random() * 500 + 1) * 4 + 1500
        }ms`,
      }}
    />
    <picture>
      <source
        type="image/avif"
        srcSet={map(
          widths,
          (w) =>
            `https://${store}.store.auroville-archives.org/${fileId}/${w}w.avif ${w}w`
        )}
        sizes="100vw"
      />
      <source
        type="image/webp"
        srcSet={map(
          widths,
          (w) =>
            `https://${store}.store.auroville-archives.org/${fileId}/${w}w.webp ${w}w`
        )}
        sizes="100vw"
      />
      <img
        data-main-image=""
        style={{ objectFit: 'cover', opacity: 1 }}
        sizes="100vw"
        decoding="async"
        src={`https://${store}.store.auroville-archives.org/${fileId}/${fallback}w.jpg`}
        srcSet={map(
          widths,
          (w) =>
            `https://${store}.store.auroville-archives.org/${fileId}/${w}w.jpg ${w}w`
        )}
        alt={title}
        width="1"
        height="1"
      />
    </picture>
  </div>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Img
