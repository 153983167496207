// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'

import Home2 from '../svg/home-2'

import Slider from '../slider'
import '../slider/style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'
// import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

export const query = graphql`
  query {
    featuredCollections: allFile(
      limit: 6
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/collections/))/" }
          autoNodeType: { eq: "resourceNode" }
        }
      }
      sort: { fields: [fields___publishedUnixTimestampAuto], order: DESC }
    ) {
      nodes {
        fields {
          pathAuto
          publishedShortHumandateAuto
        }
        childResources {
          title
          shortAbstract
          coverImage
        }
      }
    }
    img1: file(relativePath: { eq: "img1111.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const ResourceRenderer = React.memo(
  ({ node: { title, shortAbstract, coverImage } }) => (
    <Row justify="space-between">
      <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
        <StaticImage
          className="cover"
          src="https://n1.store.auroville-archives.org/96042/900w.jpg"
        />
      </Col>
      <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
        <h3>{title}</h3>
        <p>{shortAbstract}</p>
      </Col>
    </Row>
  )
)

/** [description] */
const Section = React.memo(({ mediaState: { currentWidth } }) => (
  <StaticQuery
    query={query}
    render={({
      img1,
      featuredCollections: { nodes },
      flat = map(nodes, flattenGatsbyNode),
    }) => (
      <section id="s1">
        <StaticImage
          className="cover"
          src="https://images.unsplash.com/photo-1542401886-65d6c61db217?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1800&h=600&q=80"
        />
        <div className="quote container">
          <p>
            To Be young is to be always ready to give up what we are in order to
            become what we must be. To be young is never to accept the
            irreparable. Be grateful for all ordeals, they are the shortest way
            to the Divine.
          </p>
          <p>The Mother, 1962</p>
        </div>
        <div className="sec sec1 cover-cover">
          <div className="container texts">
            <h1>
              We <span>Welcome</span> You To The
              <br />
              Auroville <span>Archives</span>
            </h1>
            <p>
              Auroville Archives has been collecting documents and media for
              over thirty years. (Read more...)
            </p>
          </div>
          <GatsbyImage image={getImage(img1)} />}
        </div>
        <div className="sec sec2 collections-abstract">
          <Home2 />
          <div className="container texts">
            <h2>
              Visit our <span>Collections</span>
            </h2>
            <p>
              Our online collections are curated to tell the stories of
              Auroville's development through the eyes of the participants.
              (Read more...)
            </p>
          </div>
          <Slider
            className="ext-arrow variable-width"
            conf={{ slidesToShow: 3, slidesToScroll: 1, centerMode: true }}
            nodes={flat}
            ResourceRenderer={ResourceRenderer}
          />
        </div>
        <div className="sec sec1 oa">
          <div className="container texts">
            <h2>
              What's <span>New</span> In The
              <br />
              <span>Open Access</span> Archive
            </h2>
            <p>
              The Open-Access Archive makes photos and documents from the
              Archives freely available. (Read more...)
            </p>
            <div className="circles">
              <div className="circle">
                <p>
                  900
                  <span>pictures</span>
                </p>
              </div>
              <div className="circle">
                <p>
                  900
                  <span>pictures</span>
                </p>
              </div>
              <div className="circle">
                <p>
                  900
                  <span>pictures</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    // headerState: state.headerState,
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSection = compose(
  withState // Add state
)(Section)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedSection
