// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useState } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import random from 'lodash/random'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import VideoPlayer from 'react-background-video-player'
import ReactPageScroller from 'react-page-scroller'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import FeaturedCollections from '../components/homepage-sections/featured-collections'
import MostRecentAdditionsToTheOpenAccessArchive from '../components/homepage-sections/most-recent-additions-to-the-open-access-archive'
import FeaturedTrueStories from '../components/homepage-sections/featured-true-stories'
import Insights from '../components/homepage-sections/insights'
import '../components/homepage-sections/style.less'

import Logo from '../components/svg/logo'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const pageSchema = {
  title: 'Auroville Archives',
  slug: '',
  abstract: about.text,
  breadcrumbs: [{ title: 'Auroville Archives', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(
      relativePath: { eq: "mock/michal-kvac-in-the-name-of-the-sun-small.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const loops = ['732300849', '732302546']

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const FirstIntro = (props) => (
  <div className="vimeo-wrapper">
    <iframe
      src={`https://player.vimeo.com/video/${
        loops[random(0, 1)]
      }?background=1&autoplay=1&loop=1&byline=0&title=0`}
      frameBorder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowFullScreen
    />
    <div>
      <Logo />
    </div>
  </div>
)

/** [description] */
const PageMatter = (props) => (
  <StandardPageWrapper className="homepage" pageSchema={pageSchema} {...props}>
    <FeaturedCollections />
  </StandardPageWrapper>
)

/** Page */
const Page = (props) => {
  const [state, setState] = useState(false)
  const {
    mediaState: { currentWidth },
  } = props

  return (
    <div id="two-page">
      <FirstIntro {...props} />
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({

  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
